<template>
  <div>
    <!-- 隐私协议弹窗 -->
    <van-popup
      v-model:show="popupShow"
      round
    >
      <div class="hint_container">
        <div class="hint_title">
          用户协议及隐私政策
        </div>
        <div class="hint_content">
          <div class="hint_text">无论数据在何处进行处理和存储，速哇始终遵守隐私政策及用户服务协议，并仅会收集开展服务运营和改进用户体验所必要的数据。</div>
          <div class="hint_text">遵照中国大陆隐私保护法律的要求，需要你签署本知情同意书。</div>
          <div class="hint_contract">
            <div class="contract_item">
              <van-checkbox class="check_box" icon-size="20px" v-model="checked" checked-color="#009944" @change="onChange"></van-checkbox>
              <div class="a-text">我同意
                <div class="active" @click="secretJump('user_service')">速哇用户服务协议</div>
              </div>
            </div>
            <div class="contract_item">
              <van-checkbox class="check_box" icon-size="20px" v-model="checked2" checked-color="#009944" @change="onChange2"></van-checkbox>
              <div class="a-text">我同意
                <div class="active" @click="secretJump('user_privacy')">速哇隐私政策</div>
              </div>
            </div>
          </div>
          <div class="hint_btn" @click="handleConfirm">
            <div :class="['btn', {checked: isChecked}]">同意并继续</div>
          </div>
        </div>
      </div>
    
    </van-popup>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  hintShow: {
    type: Boolean,
    default: true
  }
})
const checked = ref(false)
const popupShow = ref(false)
const checked2 = ref(false)
const isChecked = ref(false)
const emit = defineEmits([
  "check",
  "close",
  "onViewAgree",
]);

watch(() => props.hintShow, (val, oldVal) => {
  console.log("props.hintShow",val)
  popupShow.value = val ? true : false

  }, { immediate: true })
const onChange = (e) => {
  checked.value = e;
  isChecked.value = e && checked2.value;
}
const onChange2 = (e) => {
  checked2.value = e;
  isChecked.value = e && checked.value;
}
const handleConfirm = () =>  {
  if(checked.value && checked2.value) {
    emit("check", true)
    emit("close")

    // 清空数据
    checked.value = false
    checked2.value = false
    isChecked.value = false
  }
}

// 隐私跳转
const secretJump = (title) => {
  emit("onViewAgree", title)
}


</script>

<style lang="less" scoped>
.hint_container {
  position: relative;
  padding: 20px 0;
  .hint_title {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: 17px;
  }
  .hint_content {
    position: relative;
    width: 85%;
    margin: 10px auto 0 auto;
    .hint_text {
      margin-bottom: 7px;
      font-size: 15px;
      line-height: 25px;
      color: #444444;
    }
    .hint_contract {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      position: relative;
      margin-top: 10px;
      
      .contract_item {
        display: flex;
        margin: 5px 0;
				.check_box {
					display: flex;
				}
				.a-text {
						display: flex;
						align-items: center;
						flex-wrap: wrap;
						color: #767676;
						font-size: 14px;
						line-height: 14px;
						.active {
								color: #009944;
								margin: 0 5px;
						}
					}
      }
    }
    .hint_btn {
      display: flex;
      justify-content: center;
			align-items: center;
      position: relative;
      width: 100%;
      margin-top: 25px;
      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 150px;
        height: 40px;
        border-radius: 5px;
        background: #009944;
        color: #fff;
        font-size: 15px;
        filter: opacity(0.5)
      }
      .checked {
        filter: brightness(1);
      }
    }
  
  }
  
}


</style>